// Node Modules
import React from 'react'
import { graphql } from "gatsby"

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import Cta from '../components/Cta'
import FeaturedProjects from '../components/FeaturedProjects'
import AboutCards from '../components/AboutCards'
import Packages from '../components/Packages'

// Assets

const IndexPage = ({ data, location }) => {
  const settings = data.ctasettings.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout location={location} isOpacity={true}>
        <Seo />
        <Hero />
        <div className="w-full bg-white relative" style={{ paddingTop: "0.2px", paddingBottom: "0.2px" }}>
          <FeaturedProjects />
          <Cta link={settings.ctahome1lnk} btntxt={settings.ctahome1btn} title={settings.ctahome1title} text={settings.ctahome1text} />
          <AboutCards />
          <Packages />
          <Cta link={settings.ctahome2lnk} btntxt={settings.ctahome2btn} title={settings.ctahome2title} text={settings.ctahome2text} />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    ctasettings: file(relativePath: {eq: "settings/calltoaction.md"}) {
        childMarkdownRemark {
        frontmatter {
            ctahome1title
            ctahome1text
            ctahome1btn
            ctahome1lnk
            ctahome2title
            ctahome2text
            ctahome2btn
            ctahome2lnk
        }
      }
    }
  }
`;
