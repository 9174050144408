// Node Modules
import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactRotatingText from "react-rotating-text";
import { Carousel, CarouselItem } from "react-bootstrap";
import AnchorLink from 'react-anchor-link-smooth-scroll'

// Assets
import HeroLogo from '../images/HeroLogo';
import Button from './Button';
import IconDownArrow from '../images/IconDownArrow';

const Hero = () => {

  const [scroll, setScroll] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  let [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.pageYOffset)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("scroll", changeNav)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("scroll", changeNav)
    }
  }, [])

  let [showControls, setShowControls] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowControls(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const { herosettings } = useStaticQuery(
    graphql`
      query {
        herosettings: file(relativePath: { eq: "settings/hero.md" }) {
          id
          childMarkdownRemark {
            frontmatter {
              settingherostyle
              settingheroslider {
                type
                settingherotext
                settingheroimgLocal {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      transformOptions: { fit: COVER, cropFocus: CENTER }
                    )
                  }
                  publicURL
                }
                settingherosubheading {
                  settingherosubtextvalue
                }
                settingheroimgalt
              }
              settingherocontrols
              settingheroindicators
              settingherointerval
              settingherostatic {
                settingheroimgleftalt
                settingheroimgrighttopalt
                settingheroimgrightcenteralt
                settingheroimgrightcenterwidth
                settingheroimgrightbottomalt
              }
              settingherobtn1text
              settingherobtn1enable
              settingherobtn2text
              settingherobtn2enable
              buttononelink {
                menulink
                type
              }
              buttontwolink {
                menulink
                type
              }
            }
            settingheroimgleftLocal {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              publicURL
            }
            settingheroimgrighttopLocal {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              publicURL
            }
            settingheroimgrightcenterLocal {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              publicURL
            }
            settingheroimgrightbottomLocal {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
              publicURL
            }
          }
        }
      }
    `
  );
  const divText = {
    gridArea: "1/1",
    position: "relative",
    placeItems: "center",
    display: "grid"
  };
  const frontmatter = herosettings.childMarkdownRemark.frontmatter
  const settingherostatic = frontmatter.settingherostatic
  const interval = frontmatter.settingherointerval
  let controls = frontmatter.settingherocontrols
  let indicators = frontmatter.settingheroindicators
  const sliderlen = frontmatter.settingheroslider.length
  if (sliderlen < 2) {
    controls = false
    indicators = false
  }

  const menuOneType = frontmatter.buttononelink[0].type;
  const menuOneLink = frontmatter.buttononelink[0].menulink;
  const menuTwoType = frontmatter.buttontwolink[0].type;
  const menuTwoLink = frontmatter.buttontwolink[0].menulink;

  return (
    <>
      {frontmatter.settingherostyle === 'slider' && (
        <section className="h-screen">
          <div className="fixed top-0 w-full h-full">
            <Carousel className="" controls={controls} indicators={indicators} fade={true} interval={interval}>
              {frontmatter.settingheroslider.map(
                (slider, index) => {
                  let subheadings = []
                  slider.settingherosubheading && (
                    slider.settingherosubheading.map(heading => {
                      return (
                        subheadings.push(heading.settingherosubtextvalue)
                      )
                    }
                    ))
                  return (
                    <CarouselItem key={index} className="">
                      <div className="h-screen relative" style={{ display: "grid" }} >
                        <GatsbyImage image={slider.settingheroimgLocal.childImageSharp.gatsbyImageData} alt={slider.settingheroimgalt}
                          className="row-start-1 col-start-1"
                          layout="fullWidth"
                          aspectratio={3 / 1}
                          style={{ gridArea: "1 / 1" }}
                        />
                        {slider.settingherotext && (
                          <div style={divText} className="transition-all duration-75 transform translate-y-24" >
                            <div className="bg-template-black70 bg-opacity-90 h-auto container mx-auto w-3/4" style={{ transform: `translateY(${offsetY * 0.3}px)` }}>
                              <div className="py-1 px-9">
                                <h1 className="text-right text-2xl md:text-4xl lg:text-4xl font-thin text-white leading-tight">
                                  {slider.settingherotext}
                                </h1>
                              </div>
                              <div className="pb-6 px-9">
                                <h2 className="text-right text-lg md:text-2xl lg:text-3xl font-light text-template-orangeLight">
                                  {subheadings.length > 0 && (
                                    <ReactRotatingText className="break-words"
                                      items={subheadings}
                                    />
                                  )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </CarouselItem>
                  );
                }
              )}
            </Carousel>
          </div>
          <div className={!scroll ? showControls ? "transition-all duration-4000 ease-in-out transform opacity-100 fixed bottom-12 w-full z-10" : "opacity-0" : "transition-all duration-300 ease-in-out transform opacity-0 fixed bottom-3 w-full z-0"}>
            <div className="w-full flex flex-col justify-center sm:flex-row">
              {frontmatter.settingherobtn1enable === true &&
                <div className="px-1 pb-1 sm:pb-0 w-full flex justify-center sm:justify-end">
                  {menuOneType === "homelink" ?
                    <AnchorLink href={menuOneLink}>
                      <Button radius={"0px"} btnStyle={"light"} borderSize={"0px"} opacity={0.80}>{frontmatter.settingherobtn1text}</Button>
                    </AnchorLink>
                    :
                    <Link to={menuOneLink}>
                      <Button radius={"0px"} btnStyle={"light"} borderSize={"0px"} opacity={0.80}>{frontmatter.settingherobtn1text}</Button>
                    </Link>
                  }
                </div>
              }
              <div className="flex justify-center h-full pb-1">
                <div className="w-8 bg-template-greenDark70 hover:bg-template-greyLight70" style={{padding: "0.4rem"}}>
                  <AnchorLink href="#projects" aria-label="Scroll Down To See More">
                    <IconDownArrow width={20} className="text-template-whiteLight" />
                  </AnchorLink>
                </div>
              </div>
              {frontmatter.settingherobtn2enable === true &&
                <div className="px-1 flex w-full justify-center sm:justify-start">
                  {menuTwoType === "homelink" ?
                    <AnchorLink href={menuTwoLink}>
                      <Button radius={"0px"} btnStyle={"light"} borderSize={"0px"} opacity={0.80}>{frontmatter.settingherobtn2text}</Button>
                    </AnchorLink>
                    :
                    <Link to={menuTwoLink}>
                      <Button radius={"0px"} btnStyle={"light"} borderSize={"0px"} opacity={0.80}>{frontmatter.settingherobtn2text}</Button>
                    </Link>
                  }
                </div>
              }
            </div>
          </div>
        </section>
      )}
      {frontmatter.settingherostyle === 'static' && (
        <section className="bg-template-black100 sm:mt-20">
          <div className="herogrid">
            <div className="div12" style={{ backgroundImage: `url(${herosettings.childMarkdownRemark.settingheroimgleftLocal.publicURL})`, backgroundSize: "cover", backgroundPosition: "center" }}>
              <div className="flex justify-center items-center w-full h-full">
                <div className="bg-template-black100 w-full h-full relative opacity-70"></div>
                <HeroLogo className="z-10 absolute logo" />
              </div>
            </div>
            <div className="div22">
              <div className="h-full relative" style={{ display: "grid" }}>
                <GatsbyImage image={herosettings.childMarkdownRemark.settingheroimgrighttopLocal.childImageSharp.gatsbyImageData} alt={settingherostatic.settingheroimgrighttopalt}
                  className="row-start-1 col-start-1"
                  layout="fullWidth"
                  aspectratio={3 / 1}
                  style={{ gridArea: "1 / 1" }}
                />
              </div>
            </div>
            <div className={settingherostatic.settingheroimgrightcenterwidth === 'full' ? "div32" : "div323"}>
              <div className="h-full relative" style={{ display: "grid" }}>
                <GatsbyImage image={herosettings.childMarkdownRemark.settingheroimgrightcenterLocal.childImageSharp.gatsbyImageData} alt={settingherostatic.settingheroimgrightcenteralt}
                  className="row-start-1 col-start-1"
                  layout="fullWidth"
                  aspectratio={3 / 1}
                  style={{ gridArea: "1 / 1" }}
                />
              </div>
            </div>
            <div className="div52">
              <div className="h-full relative" style={{ display: "grid" }}>
                <GatsbyImage image={herosettings.childMarkdownRemark.settingheroimgrightbottomLocal.childImageSharp.gatsbyImageData} alt={settingherostatic.settingheroimgrightbottomalt}
                  className="row-start-1 col-start-1"
                  layout="fullWidth"
                  aspectratio={3 / 1}
                  style={{ gridArea: "1 / 1" }}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
