import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import ReactMarkdown from "react-markdown";

// Components
import Button from "./Button";

// Assets
import triangle from "../images/triangle.svg";

const AboutCards = () => {
  const { cardsettings } = useStaticQuery(graphql`
    query {
      cardsettings: file(relativePath: { eq: "settings/aboutcards.md" }) {
        id
        childMarkdownRemark {
          frontmatter {
            settingaboutcardlist {
              settingaboutcarddesc
              settingaboutcardtitle
              settingaboutcardfeatimagealt
              settingaboutcardfeatimageLocal {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                  )
                }
                publicURL
              }
            }
            settingaboutcardsectiontitle
            settingaboutcardsectionlnktext
            settingaboutcardsectionlnk
          }
        }
      }
    }
  `);
  const frontmatter = cardsettings.childMarkdownRemark.frontmatter;
  return (
    <>
      <section
        id="about"
        className="h-20 my-2 flex flex-wrap content-center justify-end"
      >
        <div className="container mx-auto flex flex-row items-center">
          <img
            src={triangle}
            alt="Nine Lives Design Triangle"
            className="w-11 h-11 xs:ml-4 sm:ml-4 md:ml-10 mr-2"
          />
          <h2 className="text-lg md:text-xl leading-7 font-light text-template-greenDark mx-2">
            {frontmatter.settingaboutcardsectiontitle}
          </h2>
        </div>
      </section>
      <section className="container mx-auto">
        <div className="grid grid-cols-1 xs:px-4 sm:px-4 md:px-10 my-2">
          <div
            className="order-none flex-1 self-auto shadow-lg"
            style={{
              backgroundImage: `url(${frontmatter.settingaboutcardlist[0].settingaboutcardfeatimageLocal.publicURL})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="py-12 bg-gray-800 opacity-90 text-white w-full sm:w-1/2 lg:w-7/12 h-full">
              <h2 className="px-6 text-lg md:text-xl font-light text-white bg-template-greenDark bg-opacity-80">
                {frontmatter.settingaboutcardlist[0].settingaboutcardtitle}
              </h2>
              <div className="px-6 mt-2 font-light text-white cmscontent cmscards">
                <ReactMarkdown linkTarget="_blank">
                  {frontmatter.settingaboutcardlist[0].settingaboutcarddesc}
                </ReactMarkdown>
                <div className="pt-4 flex justify-end">
                  <Link to={frontmatter.settingaboutcardsectionlnk}>
                    <Button radius={"0px"} btnStyle={"light"} >
                      {frontmatter.settingaboutcardsectionlnktext}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 xs:px-4 sm:px-4 md:px-10">
          <div
            className="order-none flex-1 self-auto shadow-lg flex justify-end"
            style={{
              backgroundImage: `url(${frontmatter.settingaboutcardlist[1].settingaboutcardfeatimageLocal.publicURL})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="py-12 bg-gray-800 opacity-90 text-white w-full sm:w-1/2 h-full">
              <h2 className="px-6 text-lg md:text-xl font-light text-white bg-template-greenDark bg-opacity-80">
                {frontmatter.settingaboutcardlist[1].settingaboutcardtitle}
              </h2>
              <div className="px-6 mt-2 font-light text-white cmscontent cmscards">
                <ReactMarkdown linkTarget="_blank">
                  {frontmatter.settingaboutcardlist[1].settingaboutcarddesc}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div
            className="order-none flex-1 self-auto shadow-lg"
            style={{
              backgroundImage: `url(${frontmatter.settingaboutcardlist[2].settingaboutcardfeatimageLocal.publicURL})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="py-12 bg-gray-800 opacity-90 text-white w-full sm:w-1/2 h-full">
              <h2 className="px-6 text-lg md:text-xl font-light text-white bg-template-greenDark bg-opacity-80">
                {frontmatter.settingaboutcardlist[2].settingaboutcardtitle}
              </h2>
              <div className="px-6 mt-2 font-light text-white cmscontent cmscards">
                <ReactMarkdown linkTarget="_blank">
                  {frontmatter.settingaboutcardlist[2].settingaboutcarddesc}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCards;
