import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Assets
import square from '../images/square.svg'

const FeaturedProjects = () => {
  const { gallerysettings, projects } = useStaticQuery(
    graphql`
          query {
              gallerysettings: file(relativePath: {eq: "settings/projects.md"}) {
                  id
                  childMarkdownRemark {
                      frontmatter {
                        settingprojtitle
                      }
                  }
              }
              projects: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(projects)/.*[.]md$/"}, frontmatter: {projnumber: {lt: 10}}}
                sort: {fields: frontmatter___projnumber, order: ASC}
              ) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    id
                    featuredimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          layout: FULL_WIDTH
                        )
                      }
                    }
                    frontmatter {
                      title
                      projnumber
                      featuredimagealt
                    }
                  }
                }
              }
          }
        `
  );
  const settings = gallerysettings.childMarkdownRemark.frontmatter
  return (
    <>
      <section id="projects" className="h-20 my-2 flex flex-wrap content-center justify-end">
        <div className="container mx-auto flex flex-row items-center">
          <img src={square} alt="Nine Lives Design Square" className="w-11 h-11 xs:ml-4 sm:ml-4 md:ml-10 mr-2" />
          <h2 className="text-lg md:text-xl leading-7 font-light text-template-greenDark mx-2">{settings.settingprojtitle}</h2>
        </div>
      </section>
      <section className="projects container mx-auto">
        <div className="gallery homeGallery xs:px-4 sm:px-4 md:px-10">
          { projects.edges.map(edge => {
                const frontmatter = edge.node.frontmatter
                const image = getImage(edge.node.featuredimageLocal)
                let titleNo = frontmatter.projnumber;
                if (titleNo < 10) { titleNo = "0" + titleNo };
                return (
                  <div className="imgContainer imgContainerHover" key={edge.node.id} >
                    <Link to={`/projects${edge.node.fields.slug}/`}>
                      <div className="grid h-full" >
                        <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                          className="row-start-1 col-start-1 imgWrapper"
                          layout="fullWidth"
                          aspectratio={3 / 1}
                          style={{ gridArea: "1 / 1" }}
                        />
                        <div className="title">
                          <h2>{`${titleNo}: ${frontmatter.title}`}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
        </div>
      </section>
    </>
  )
}

export default FeaturedProjects