import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import { openPopupWidget } from "react-calendly";
import Button from './Button';

// Assets
import circle from '../images/circle.svg'

const Packages = () => {
  const { packages, packagessettings } = useStaticQuery(
    graphql`
          query {
            packages: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(packages)/.*[.]md$/"}, frontmatter: {production: {eq: true}}}
                limit: 4
                sort: {fields: frontmatter___disporder, order: ASC}
              ) {
                edges {
                  node {
                    featuredimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          layout: FULL_WIDTH
                        )
                      }
                      publicURL
                    }
                    frontmatter {
                      title
                      disporder
                      description
                      price
                      link
                      featuredimage
                      featuredimagealt
                    }
                  }
                }
              }
              packagessettings: file(relativePath: {eq: "settings/packages.md"}) {
                id
                childMarkdownRemark {
                  frontmatter {
                    settingpackagestitle
                    settingpackageslnkbtn
                  }
                }
              }
          }
        `
  );
  const settings = packagessettings.childMarkdownRemark.frontmatter
  return (
    <>
      <section id="packages" className="h-20 my-2 flex flex-wrap content-center justify-end">
        <div className="container mx-auto flex flex-row items-center">
          <img src={circle} alt="Nine Lives Design Circle" className="w-11 h-11 xs:ml-4 sm:ml-4 md:ml-10 mr-2" />
          <h2 className="text-lg md:text-xl leading-7 font-light text-template-greenDark mx-2">{settings.settingpackagestitle}</h2>
        </div>
      </section>
      <section className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 xs:px-4 sm:px-4 md:px-10">
          {packages.edges.map((edge, index) => {
            const frontmatter = edge.node.frontmatter
            const image = getImage(edge.node.featuredimageLocal)
            const url = frontmatter.link
            const onClickPack = () => openPopupWidget({ url });
            return (
              <div className="flex flex-col overflow-hidden bg-white shadow-lg" key={index}>
                <div className="grid w-full" >
                  <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                    className="max-h-80 xl:max-h-96"
                    layout="fullWidth"
                    aspectratio={3 / 1}
                    style={{ gridArea: "1 / 1" }}
                  />
                </div>
                <div className="w-full h-full p-4 md:p-4 flex flex-col bg-white">
                  <h3 className="text-lg md:text-xl text-gray-800">{frontmatter.title}</h3>
                  <div className="mt-2 text-gray-600 cmscontent cmscards text-sm md:text-lg">
                    <ReactMarkdown linkTarget="_blank">
                      {frontmatter.description}
                    </ReactMarkdown>
                  </div>
                  <div className="flex justify-between mt-3 h-full items-end">
                    <div className="text-sm md:text-base font-semibold text-gray-700">{frontmatter.price > 0 ? "£" + frontmatter.price : "FREE"}</div>
                    <div>
                      <Button radius={"0px"} btnStyle={"dark"} borderSize={"0px"} borderColor={"rgba(255, 255, 255)"} onClick={onClickPack} fontWeight={400}>{settings.settingpackageslnkbtn}</Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Packages