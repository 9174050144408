import * as React from "react"

function HeroLogo(props) {
  return (
    <svg
      viewBox="0 0 3542 5409"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path fill="none" d="M0 0h3538.21v5406.29H0z" />
      <g fill="#415c5c">
        <path
          d="M2185.89 5057.25l91.287 96.083v-95.779h22.963v147.979h-6.5l-90.25-94.341v94.408h-23.646v-148.35h6.146zM2061.36 5151.46l-30.038.2v-23.104h56v55.225l-.45.575s-6 7.629-12.433 12.933c-2.959 2.438-6.03 4.367-8.692 5.046-8.221 2.096-17.383 3.171-27.508 3.171-14.117 0-26.542-3.112-37.25-9.271-10.784-6.187-19.171-14.929-25.2-26.225-5.971-11.191-8.984-24.391-8.984-39.55 0-15.058 3.225-28.191 9.638-39.366 6.429-11.288 14.954-20.813 26.679-26.159 11.996-5.45 23.813-8.404 39.008-7.575 15.867.871 30.634 9.763 42.071 20.279l4.075 4.1-16.687 20.246-1.5-2.725s-5.405-9.754-17.7-14.554c-8.35-3.258-19.83-4.192-34.917 1.529-7.579 2.854-14.321 11.125-17.604 18.567-3.396 7.637-5.063 16.158-5.063 25.575 0 9.846 1.505 18.683 5.121 26.262 5.921 12.334 17.133 17.692 28.4 19.763 14.258 2.621 28.675.017 33.4-1.004l-.367-23.938zM1846.66 5057.22h28.679v148.283h-28.679zM1742.33 5092.21l7.429-22.216-3.621-1.538c-6.475-2.746-13.066-6.137-19.912-7.791-8.792-2.1-17.254-3.688-26.317-3.421-10.879.296-22.537 2.262-31.408 9.166-10.146 7.871-17.196 21.592-17.196 34.78 0 8.462 5.313 15 9.55 19.945 4.05 4.742 9.075 8.6 15.158 11.492 5.713 2.775 11.992 5.038 18.767 6.763 6.329 1.608 12.192 3.429 17.508 5.479 5.059 1.916 9.2 4.283 12.563 7.116 2.712 2.309 3.966 5.55 3.966 9.663 0 6.433-2.466 11.246-7.583 14.408-5.8 3.554-13.037 5.254-21.658 5.254-4.034 0-7.984-.279-11.821-.783-11.671-1.5-19.504-3.629-23.325-6.954l-5.204-4.529-6.492 25.316 2.3 1.705c4.638 3.433 9.15 4.816 17.933 6.729 8.259 1.8 16.846 2.704 25.775 2.704 18.188 0 32-3.917 41.513-11.354 9.879-7.738 14.946-18.813 14.946-33.313 0-14.662-7.092-25.779-21.567-33.196-5.708-3.025-11.983-5.558-18.812-7.45a289.93 289.93 0 01-17.717-5.562c-5.054-1.754-9.263-3.904-12.617-6.388-2.679-2.012-3.754-5.283-3.754-9.65 0-4.566 2.579-8.304 7.358-11.383 5.55-3.608 13.484-5.158 23.48-3.737 11.083 1.554 20.295 5.241 26.72 7.395l4.038 1.35z"
          fillRule="nonzero"
        />
        <path d="M1460.75 5178.71h99.079v26.808h-99.079zM1460.75 5117.96h87.412v26.804h-87.412zM1460.75 5057.25h99.079v26.804h-99.079z" />
        <path
          d="M1347.43 5080.88c-14.558-15.771-36.754-23.658-66.587-23.658h-37.679v148.283h38.179c28.708 0 50.317-.733 65.375-16.433s22.567-33.759 22.567-54.15c-.034-20.267-7.313-38.271-21.855-54.042zm-20.091 86.192c-7.85 9.708-19.142 10.154-34.129 10.154h-19.946v-91.638h19.679c15.591 0 27.171 4.892 34.771 14.634 7.6 9.758 11.4 20.854 11.4 33.362.021 12.633-3.905 23.783-11.775 33.488z"
          fillRule="nonzero"
        />
      </g>
      <g fill="#fff">
        <path
          d="M897.713 4795.58h101.112v26.463H866.471v-257.379h31.241v230.916z"
          fillRule="nonzero"
        />
        <path d="M1263.94 4564.67h31.258v257.379h-31.258z" />
        <path
          d="M1677.63 4822.04h-5.9l-111.412-257.38 34.204.001 79.417 193.408 80.537-193.409h34.921l-111.767 257.38zM2528.08 4627.04c0 62.754 143.655 30.666 143.655 126.204-.001 38.133-36.009 68.796-87.338 68.796-36.708 0-60.596-7.488-88.408-21.75 4.279-9.625 7.491-15.671 11.412-24.23 22.463 9.984 47.046 17.83 74.146 17.83 30.304 0 55.975-15.688 57.742-39.934 5.704-70.954-138.305-34.941-142.23-122.279-1.783-45.267 32.446-67.017 76.305-67.017 14.241 0 46.687 3.213 83.412 18.525-3.937 9.984-5.362 16.4-9.271 25.334-17.112-5.704-47.062-14.975-73.091-14.975-23.534 0-45.263 8.896-46.334 33.141v.355z"
          fillRule="nonzero"
        />
        <path d="M2054.51 4564.67h176.367v30.75H2054.51zM2054.51 4791.29h176.367v30.75H2054.51zM2054.51 4677.13h152.583v30.75H2054.51z" />
      </g>
      <g fill="#fff">
        <path d="M2913.81 3682.45h410.171v71.508H2913.81zM2913.81 4261.63h410.171v71.512H2913.81zM2913.81 3972.67h353.992v71.513H2913.81z" />
        <path
          d="M1878.39 3682.27l430.996 453.629V3682.27h80.937v650.854h-11.558l-428.754-448.146v447.275h-84.025V3682.27h12.404zM1262.07 3682.45h80.433v650.671h-80.433zM738.579 4333.13h-11.558L298.25 3884.984v447.275h-84.029v-649.8H226.8l430.825 453.446v-453.629h80.954v650.854z"
          fillRule="nonzero"
        />
      </g>
      <g fill="#415c5c">
        <path
          d="M1753.9 278.863l1513.51 2997.11H270.49L1753.9 278.863m-.296-77.925l-1539.27 3109.96h3109.76l-1570.5-3109.96z"
          fillRule="nonzero"
        />
        <path d="M2659.95 1358.78c-366.554 0-664.15 297.596-664.15 664.15 0 366.55 297.596 664.146 664.15 664.146s664.15-297.596 664.15-664.146c0-366.554-297.596-664.15-664.15-664.15zm0 34.721c347.387 0 629.425 282.037 629.425 629.429 0 347.387-282.038 629.425-629.425 629.425-347.392 0-629.429-282.037-629.429-629.425 0-347.392 282.037-629.429 629.429-629.429z" />
        <path d="M3324.34 200.704H213.87v3110.44h3110.47l-.001-3110.44zM248.6 235.429v3040.99h3041.03V235.429H248.6z" />
      </g>
    </svg>
  )
}

export default HeroLogo
